import React, { useEffect, useState } from "react";
import { httpReauest } from "../api/api";
import { CDN_BASE_URL } from "../config";

const AllGallery = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  async function getData(query) {
    const data2 = await httpReauest("GET", `/user/hamayesh`, {}, {});
    setData(data2?.data?.data);
  }
  return (
    <div className="bg-pattern2 pt-5">
      <div className="z-10">
        <h1 className="text-center text-black">گالری روادید گذشته</h1>
        <div className="row pt-4 px-3 justify-content-center">
          {data?.map((data) => {
            if (data.end) {
              return (
                <div className="col-lg-3 col-md-6 col-12 p-3">
                  <a
                    href={"/gallery/" + data._id}
                    className="d-flex flex-column align-items-center"
                  >
                    <img
                      style={{ height: "400px", cursor: "pointer" }}
                      className="w-100 rounded border border-primary"
                      src={CDN_BASE_URL + data?.img}
                      alt={data?.name}
                    />
                    {data.end ? (
                      <>
                        <h6 className="pt-4 pb-3 text-center">برگزار شد</h6>
                        <a
                          href={"/gallery/" + data._id}
                          className="btn btn-primary mt-4"
                        >
                          دیدن گالری
                        </a>
                      </>
                    ) : (
                      <>
                        <h6 className="pt-4 pb-3 text-center">
                          زمان باقی مانده
                        </h6>

                        <a
                          href={"/hamayesh/" + data._id}
                          className="btn btn-primary mt-3"
                        >
                          اطلاعات بیشتر
                        </a>
                      </>
                    )}
                  </a>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default AllGallery;
