import React from "react";

const Alert = ({ type, contnet }) => {
  if (type == "success") {
    return (
      <div className="w-100 p-0 m-0 p-3 text-white bg-primary rounded p-lg-3">
        <p className="mb-0">{contnet}</p>
      </div>
    );
  } else {
    return (
      <div className="w-100 p-0 m-0 p-2 text-white bg-danger rounded p-lg-3">
        <p className="mb-0">{contnet}</p>
      </div>
    );
  }
};

export default Alert;
