import React from "react";

const Footer = () => {
  return (
    <>
      <div
        className="container-fluid bg-dark text-white-50 footer pt-4  wow fadeIn "
        data-wow-delay="0.1s"
      >
        <div className="pattern_ab"></div>
        <div className="container py-4 z-10">
          <div className="row g-5 justify-content-center px-lg-4">
            <div id="contact" className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4 ">ارتباط با ما</h5>
              <a
                href="tel:0936 210 4206"
                dir="ltr"
                className="text-end text-white"
              >
                0936 210 4206
                <i className="fab fa-whatsapp ms-2 fs-4"></i>
                <br />
                <small> (از واتساپ ارتباط بگیرید)</small>
              </a>
              <div className="d-flex pt-4">
                <a
                  className="btn btn-outline-light fs-5 btn-social"
                  href="https://t.me/joinchat/A_J3kVOof91MFJgXQE6uLg"
                >
                  <i className="fab fa-telegram"></i>
                </a>
                <a
                  className="btn btn-outline-light fs-5 btn-social"
                  href="https://www.instagram.com/sazman_shishei?igsh=MTcza3ZiYXpyOHh0bQ=="
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4 ">لینک های اصلی</h5>
              <a className="btn btn-link text-white-50 text-end " href="/">
                صفحه اصلی
              </a>
              <a className="btn btn-link text-white-50 text-end " href="/about">
                درباره ما
              </a>
              <a className="btn btn-link text-white-50 text-end " href="/sign">
                عضویت
              </a>
              <a
                className="btn btn-link text-white-50 text-end "
                href="https://www.tceo.ir/"
              >
                پرتال سازمان نظام مهندسي ساختمان استان تهران
              </a>
            </div>
          </div>
        </div>
        <div className="container z-10">
          <div className="copyright">
            <div className="row">
              <div className="col-12 text-center mb-3 mb-md-0">
                © تمامی حقوق محفوظ است
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

export default Footer;
