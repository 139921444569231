import React from "react";
import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  return (
    <div className="container-fluid nav-bar bg-transparent">
      <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4 py-2">
        <a
          href="/"
          className="navbar-brand d-flex align-items-center text-center"
        >
          <div className=" p-2 ms-2">
            <img
              className="img-fluid"
              src="/logo.png"
              alt="Icon"
              style={{ width: "65px", height: "65px" }}
            />
          </div>
          <h1 className="m-0 text-primary">گروه شمع</h1>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          onClick={() => setShow(!show)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto">
            <a href="/" className="nav-item nav-link active">
              صفحه اصلی
            </a>
            <span className="nav-item nav-link nav_hover">
              <FaAngleDown /> رویدادها
              <span className="shadow">
                <a href="/hamayesh?type=normal">همایش ها</a>
                <a href="/">نشست های آموزشی</a>
                <a href="/hamayesh?type=sport">گردهمایی ورزشی تفریحی</a>
                <a href="/">تور های نمایشگاهی</a>
              </span>
            </span>
            <a href="/gallery" className="nav-item nav-link">
              گالری رویداد ها
            </a>
            <a href="/about" className="nav-item nav-link">
              درباره ما
            </a>
            <a href="#contact" className="nav-item nav-link">
               ارتباط با ما
            </a>

            {/* <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Property
                </a>
                <div className="dropdown-menu rounded-0 m-0">
                  <a href="property-list.html" className="dropdown-item">
                    Property List
                  </a>
                  <a href="property-type.html" className="dropdown-item">
                    Property Type
                  </a>
                  <a href="property-agent.html" className="dropdown-item">
                    Property Agent
                  </a>
                </div>
              </div> */}
            {/* <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div className="dropdown-menu rounded-0 m-0">
                  <a href="testimonial.html" className="dropdown-item">
                    Testimonial
                  </a>
                  <a href="404.html" className="dropdown-item">
                    404 Error
                  </a>
                </div>
              </div> */}
          </div>
          <a href="/sign" className="btn btn-primary px-3 d-none d-lg-flex">
            عضویت
          </a>
        </div>
        {show && (
          <div
            className="collapse navbar-collapse d-block d-lg-none"
            id="navbarCollapse"
          >
            <div className="navbar-nav ms-auto">
              <a onClick={()=>setShow(false)} href="/" className="nav-item nav-link active">
                صفحه اصلی
              </a>
              <span
                onClick={() => setShow2(!show2)}
                className="nav-item nav-link"
              >
                رویدادها <FaAngleDown />
                {show2 && (
                  <span className="d-flex flex-column gap-2 p-3">
                    <a onClick={()=>setShow(false)} href="/hamayesh?type=normal" className="text-dark">
                      همایش ها
                    </a>
                    <a onClick={()=>setShow(false)} href="/" className="text-dark">
                      نشست های آموزشی
                    </a>
                    <a onClick={()=>setShow(false)} href="/hamayesh?type=sport" className="text-dark">
                      گردهمایی ورزشی تفریحی
                    </a>
                    <a onClick={()=>setShow(false)} href="/" className="text-dark">
                      تور های نمایشگاهی
                    </a>
                  </span>
                )}
              </span>
              <a onClick={()=>setShow(false)} href="/gallery" className="nav-item nav-link">
                گالری رویداد ها
              </a>
              <a onClick={()=>setShow(false)} href="/about" className="nav-item nav-link">
                درباره ما
              </a>
              <a onClick={()=>setShow(false)} href="#contact" className="nav-item nav-link">
                 ارتباط با ما
              </a>
            </div>
            <a onClick={()=>setShow(false)} href="/sign" className="btn btn-primary px-3 d-none d-lg-flex">
              عضویت
            </a>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;
