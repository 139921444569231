import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { httpReauest } from "../api/api";
import { CDN_BASE_URL } from "../config";

const Gallery = () => {
  const [data, setData] = useState();
  const { id } = useParams();

  useEffect(() => {
    getData(id);
  }, []);

  async function getData(query) {
    const data2 = await httpReauest("GET", `/user/hamayesh/${query}`, {}, {});
    setData(data2?.data?.data);
  }

  return (
    <div className="pt-7 bg-dark container-fluid header px-0">
      <div className="w-100 p-0 m-0 bg-pattern2 pt-4">
        <div className="z-10  row w-100">
          {data?.images?.length ? (
            data?.images?.map((image) => {
              return (
                <span className="col-lg-4 col-md-6 col-12 p-3">
                  <img
                    src={CDN_BASE_URL + image}
                    className=" rounded-2 p-0 w-100"
                    alt="gallery"
                  />
                </span>
              );
            })
          ) : (
            <h1 className="text-center"> عکسی وجود ندارد</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
