import React, { useState } from "react";
import { FaCheck, FaCheckCircle, FaTrash, FaUser } from "react-icons/fa";
import { httpReauest } from "../api/api";
import { CDN_BASE_URL } from "../config";
import { toast } from "react-toastify";

const UserCard = ({
  name,
  phone,
  prefix,
  nationalCode,
  company,
  desc,
  signCode,
  sended,
  hamayesh,
  lesson,
  request,
  email,
  withh,
  approve,
  userId,
  vip,
}) => {
  const [check, setCheck] = useState();
  async function handleSubmit() {
    if (hamayesh) {
      if (hamayesh == "6591276f79f8914990367996") {
        const data = await httpReauest("POST", "/user/hamayesh/22/send/sms", {
          phone,
          email,
        });
        if (data.status == 200) {
          toast.success("successfull");
          setCheck(true);
        } else {
          toast.error(data.message);
        }
      } else {
        const data = await httpReauest("POST", "/user/hamayesh/send/sms", {
          phone,
          email,
          hamayeshId: hamayesh,
        });
        if (data.status == 200) {
          toast.success("successfull");
          setCheck(true);
        } else {
          toast.error(data.message);
        }
      }
    } else {
      const data = await httpReauest("POST", "/user/send/sms", {
        phone,
        email,
      });
      if (data.status == 200) {
        toast.success("successfull");
        setCheck(true);
      } else {
        toast.error(data.message);
      }
    }
  }

  async function handleSubmitVIP() {
    if (hamayesh) {
      if (hamayesh == "6591276f79f8914990367996") {
        const data = await httpReauest("POST", "/user/hamayesh/22/send/sms", {
          phone,
          email,
        });
        if (data.status == 200) {
          toast.success("successfull");
          setCheck(true);
        } else {
          toast.error(data.message);
        }
      } else {
        const data = await httpReauest("POST", "/user/hamayesh/send/sms/vip", {
          phone,
          email,
          hamayeshId: hamayesh,
        });
        if (data.status == 200) {
          toast.success("successfull");
          setCheck(true);
        } else {
          toast.error(data.message);
        }
      }
    } else {
      const data = await httpReauest("POST", "/user/send/sms", {
        phone,
        email,
      });
      if (data.status == 200) {
        toast.success("successfull");
        setCheck(true);
      } else {
        toast.error(data.message);
      }
    }
  }

  async function handleRepeat() {
    if (vip) {
      const data = await httpReauest("POST", "/user/hamayesh/send/sms/vip", {
        phone,
        email,
        hamayeshId: hamayesh,
      });
      if (data.status == 200) {
        toast.success("successfull");
      } else {
        toast.error(data.message);
      }
    } else {
      const data = await httpReauest("POST", "/user/hamayesh/send/sms", {
        phone,
        email,
        hamayeshId: hamayesh,
      });
      if (data.status == 200) {
        toast.success("successfull");
      } else {
        toast.error(data.message);
      }
    }
  }

  async function handleDelete() {
    await httpReauest("DELETE", "/user/hamayesh_user/" + userId, {}, {})
      .then(() => {
        toast.success("موفقیت امیز");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }
  return (
    <div dir="rtl" className="col-md-4 p-2">
      <div className="border border-white rounded-4 text-white p-3 d-flex justify-content-center flex-wrap">
        <FaUser size={45} />
        <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
          <span>شماره تماس:</span>
          <span>0{phone}</span>
        </span>
        <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
          <span>پیشوند</span>
          <span>{prefix}</span>
        </span>
        <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
          <span>نام و نام خانوادگی:</span>
          <span>{name}</span>
        </span>
        <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
          <span>ایمیل :</span>
          <span>{email}</span>
        </span>
        {!request && (
          <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
            <span>کدملی:</span>
            <span>{nationalCode}</span>
          </span>
        )}
        {!request && (
          <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
            <span>رشته تحصیلی:</span>
            <span>{lesson}</span>
          </span>
        )}
        {hamayesh == "6591274679f8914990367994" && (
          <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
            <span>موافقت با دریافت گواهیی:</span>
            <span>{approve ? "موافقت شده" : "موافقت نشده"}</span>
          </span>
        )}
        <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
          <span>تعداد همراهان:</span>
          <span>{withh}</span>
        </span>
        <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
          <span>عنوان سازمان/شرکت/دانشگاه:</span>
          <span>{company ? company : "__"}</span>
        </span>
        {!request && (
          <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6">
            <span>کد عضویت نظام مهندسی:</span>
            <span>{signCode ? signCode : "__"}</span>
          </span>
        )}
        <span className="w-100 p-0 m-0 d-flex justify-content-between my-2 fs-6 flex-wrap">
          <span>مختصری از رزومه:</span>
          <span>{desc ? desc : "__"}</span>
        </span>
        {!request && (sended || check) ? (
          <FaCheckCircle color="green" size={35} className="mt-4" />
        ) : (
          <>
            <button
              onClick={handleSubmit}
              className="btn btn-danger mt-4 fs-6 mx-1"
            >
              ارسال دعوتنامه
            </button>
            <button
              onClick={handleSubmitVIP}
              className="btn btn-danger mt-4 fs-6 mx-1"
            >
              ارسال دعوتنامه VIP
            </button>
          </>
        )}
        <button
          onClick={handleRepeat}
          className="btn btn-danger mt-4 fs-6 mx-1"
        >
          ارسال مجدد
        </button>
        <button
          onClick={handleDelete}
          className="btn btn-danger mt-4 fs-6 mx-1"
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
};

export default UserCard;
