import React, { useEffect, useState } from "react";
import { httpReauest } from "../api/api";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import Navbar from "../components/Navbar";
import { BASE_URL, CDN_BASE_URL } from "../config";
import Alert from "../components/Alert";
import { FaPlus, FaTrash } from "react-icons/fa";

const Sponser = () => {
  const [cookies, setCookie] = useCookies(["admin"]);
  const [logined, setlogined] = useState();
  const [name, setname] = useState();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);

  useEffect(() => {
    stayLogin();
    getSponsers();
  }, []);

  async function stayLogin() {
    await httpReauest(
      "POST",
      "/admin/islogined",
      {},
      { "x-auth-token": cookies?.admin }
    )
      .then(({ status }) => {
        if (status === 201) {
          setlogined(true);
        } else {
          window.location.href = window.location.origin;
        }
      })
      .catch((e) => {
        window.location.href = window.location.origin;
      });
  }

  async function getSponsers() {
    const data = await httpReauest("GET", "/user/sponser", {}, {});

    const newHamayesh = data.data.data;

    setname(data.data.data.name);

    for (let index = 0; index < newHamayesh?.images.length; index++) {
      const response = await fetch(CDN_BASE_URL + newHamayesh?.images[index]);
      const blob = await response.blob();
      const file = new File([blob], newHamayesh?.images[index], {
        type: blob.type,
      });
      handleChangeImg2(file);
    }
  }

  async function hadnleSend(params) {
    const formdata = new FormData();

    for (let i = 0; i < images.length; i++) {
      formdata.append("image", images[i]);
    }
    formdata.append("name", name);

    await fetch(BASE_URL + "/user/sponser/", {
      method: "POST",
      body: formdata,
    })
      .then((dat) => {
        if (dat?.status == 200) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        setErrors(err.response?.data?.message);
      });
  }

  function handleChangeImg(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImage(fils[index]);
        }
      }
    }
  }

  async function handleChangeImg2(e) {
    const repeat = await images.find((s) => s.name == e.name);
    if (repeat) {
      return null;
    }
    if (e.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImages((current) => [...current, e]);
      setPreviwImage(e);
    }
  }

  async function setPreviwImage(img) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(img);
  }

  async function handleDelete(image) {
    const newPreviews = previews.filter((e) => e !== image);
    setPreview(newPreviews);

    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], image, { type: blob.type });

    const newImages = images.filter((i) => i.size !== blob.size);
    setImages(newImages);
  }

  return (
    <>
      {logined ? (
        <div
          dir="rtl"
          style={{
            position: "relative",
            overflow: "visible",
            minHeight: "100vh",
          }}
          className="w-100 m-0 jumbotron  d-flex flex-wrap bg-dark pt-5 pt-md-0"
        >
          <Navbar />
          <div className="col-md-9 col-xl-10 px-0  pr-md-4">
            <div className="row m-0  p-3 w-100 ">
              <form className="w-100 d-flex flex-wrap px-4 px-lg-5">
                <>
                  <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                    <label className="text-white">متن اسپانسر</label>
                    <br />
                    <input
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      className="w-100 form-control mt-2"
                    />
                  </span>
                  <span className=" p-3 col-12">
                    <label className="text-white"> عکس ها</label>
                    <br />
                    <label
                      htmlFor="addImage"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        position: "relative",
                        background: "gold",
                        width: "fit-content",
                        padding: "10px",
                        borderRadius: "5px",
                        margin: "19px 0",
                      }}
                    >
                      <FaPlus size={25} color="#000" /> آپلود عکس
                    </label>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "15px",
                      }}
                    >
                      {previews &&
                        previews.map((src, i) => {
                          return (
                            <span
                              style={{
                                position: "relative",
                              }}
                              key={i}
                            >
                              <img
                                src={src}
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                                alt=""
                              />
                              <FaTrash
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDelete(src)}
                              />
                            </span>
                          );
                        })}
                    </div>

                    <input
                      onChange={(e) => {
                        handleChangeImg(e);
                      }}
                      style={{ display: "none" }}
                      id="addImage"
                      type={"file"}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </span>
                  <button
                    type="button"
                    onClick={hadnleSend}
                    className="btn btn-danger mx-2"
                  >
                    تایید
                  </button>
                </>
                <span className="col-12 p-3">
                  {success && (
                    <Alert
                      type={"success"}
                      contnet={"عکس ها با موفقیت اپلود شدند"}
                    />
                  )}
                  {errors && <Alert type={"error"} contnet={errors} />}
                </span>
              </form>
            </div>
          </div>
        </div>
      ) : (
        "loadnig..."
      )}
    </>
  );
};

export default Sponser;
