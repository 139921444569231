import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { httpReauest } from "../api/api";

const SigninFormAdmin = () => {
  const [email, setEmail] = useState();
  const [name, setname] = useState();
  const [password, setPassword] = useState();
  const [cookies, setCookie] = useCookies(["admin"]);

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    if (name && password) {
      await httpReauest("POST", "/admin/login", { email: name, password }, {})
        .then((data) => {
          toast.success(data.data.message);
          setCookie("admin", data.data.data.token, { path: "/" });
          navigate("/admin/dashboard");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      toast.error("check all fild");
    }
  }
  return (
    <div
      style={{
        background: "linear-gradient(to right, #333399, #ff00cc)",
        zIndex: "10",
        width: "380px",
      }}
      className=" p-4 rounded shadow-lg"
    >
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8 text-right">
        <h2 className="text-white mb-4">همین حالا وارد شوید</h2>

        <form className="space-y-4 md:space-y-5" action="#">
          <div className="py-1">
            <label
              htmlFor="phone"
              className="block mb-2 text-sm font-medium text-white"
            >
              نام کاربری
            </label>
            <input
              dir="ltr"
              type="text"
              name="phone"
              id="phone"
              onChange={(e) => {
                setname(e.target.value);
              }}
              className="form-control"
              placeholder="admin"
            />
          </div>
          <div className="py-1">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-white"
            >
              رمز
            </label>
            <input
              type="password"
              name="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              id="password"
              placeholder="••••••••"
              className="form-control"
            />
          </div>

          <button
            onClick={(e) => handleSubmit(e)}
            className="btn btn-primary mt-4 mb-2 px-4 py-2"
          >
            ورود
          </button>
        </form>
      </div>
    </div>
  );
};

export default SigninFormAdmin;
