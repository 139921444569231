import React from "react";

const NotFound = () => {
  return (
    
    <div className="d-flex justify-content-center py-4">
      <img
        src="https://img.freepik.com/free-vector/404-error-with-landscape-concept-illustration_114360-7898.jpg?size=626&ext=jpg&ga=GA1.1.1788614524.1703116800&semt=ais"
        className="col-md-6 col-12"
      />
    </div>
  );
};

export default NotFound;
