import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  FaAlignJustify,
  FaAngleLeft,
  FaArrowRight,
  FaRegEye,
  FaUser,
  FaUserCheck,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { httpReauest } from "../api/api";
import UserCard from "../components/UserCard";
import Navbar from "../components/Navbar";
import { BASE_URL } from "../config";

const AdminDashboard = () => {
  const [cookies, setCookie] = useCookies(["admin"]);
  const [logined, setlogined] = useState();
  const [allUser, setallUser] = useState();
  const [show, setshow] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest(
      "POST",
      "/admin/islogined",
      {},
      { "x-auth-token": cookies?.admin }
    )
      .then(({ status }) => {
        if (status === 201) {
          setlogined(true);
        } else {
          window.location.href = window.location.origin;
        }
      })
      .catch((e) => {
        window.location.href = window.location.origin;
      });

    await httpReauest("GET", "/user", {}, {}).then(({ data }) => {
      console.log(data);
      setallUser(data.data);
    });
  }

  function handleShow() {
    show ? setshow(false) : setshow(true);
  }

  return (
    <>
      {logined ? (
        <div
          dir="rtl"
          style={{
            position: "relative",
            overflow: "visible",
            minHeight: "100vh",
          }}
          className="w-100 m-0 jumbotron  d-flex flex-wrap bg-dark"
        >
          <Navbar />
          <div className="col-md-9 col-xl-10 px-0  pr-md-4">
            <div className="row m-0  p-3 w-100 ">
              <div className="w-100 p-0 py-3 d-flex justify-content-center">
                <a
                  className="btn btn-primary"
                  download
                  href={BASE_URL + "/admin/get/csv/user"}
                >
                  دریافت فایل اکسل
                </a>
              </div>
              {allUser?.length == 0 || !allUser ? (
                <h1 className="text-center w-100 text-white">
                  کاربری موجود نیست
                </h1>
              ) : (
                allUser?.map((user) => {
                  return (
                    <UserCard
                      key={user._id}
                      plan={user.plan}
                      payDoc={user.payDoc}
                      phone={user.phone}
                      name={user.name}
                      prefix={user.prefix}
                      nationalCode={user.nationalCode}
                      company={user.company}
                      desc={user.desc}
                      signCode={user.signCode}
                      email={user.email}
                      hamayesh={user.hamayesh}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      ) : (
        "loadnig..."
      )}
    </>
  );
};

export default AdminDashboard;
