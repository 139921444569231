import "./index.scss";
import "./App.css";
import "./lib/animate/animate.min.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./js/main";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Sign from "./pages/Sign";
import NotFound from "./components/404";
import Hamayesh from "./pages/Hamayesh";
import HamayeshDetail from "./pages/HamayeshDetail";
import SignHamayesh from "./pages/SignHamayesh";
import AdminLogin from "./pages/AdminLogin";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/Layout";
import AdminDashboard from "./pages/AdminDashboard";
import AdminHamayesh from "./pages/AdminHamayesh";
import Expo from "./pages/Exop";
import Gallery from "./pages/Gallery";
import AdminRequest from "./pages/AdminRequest";
import About from "./pages/About";
import Invite from "./pages/Invite";
import CreateHmayesh from "./pages/CreateHmayesh";
import EditHmayesh from "./pages/EditHamayesh";
import UploadPhoto from "./pages/UploadPhoto";
import AllGallery from "./pages/AllGallery";
import Sponser from "./pages/Sponser";
import Ads from "./pages/Ads";
import Video from "./pages/Video";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Layout>
            <Home />
          </Layout>
        </>
      ),
    },
    {
      path: "/sign",
      element: (
        <>
          <Layout>
            <Sign />
          </Layout>
        </>
      ),
    },
    {
      path: "/invite/:hamayeshId/:id/:vip",
      element: (
        <>
          <Invite />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <Layout>
            <About />
          </Layout>
        </>
      ),
    },
    {
      path: "/admin/login",
      element: <AdminLogin />,
    },
    {
      path: "/admin/dashboard",
      element: <AdminDashboard />,
    },
    {
      path: "/admin/dashboard/gallery",
      element: <UploadPhoto />,
    },
    {
      path: "/admin/dashboard/sponser",
      element: <Sponser />,
    },
    {
      path: "/admin/dashboard/ads",
      element: <Ads />,
    },
    {
      path: "/admin/dashboard/video",
      element: <Video />,
    },
    {
      path: "/admin/dashboard/create",
      element: <CreateHmayesh />,
    },
    {
      path: "/admin/dashboard/edit",
      element: <EditHmayesh />,
    },
    {
      path: "/admin/hamayesh/:id",
      element: <AdminHamayesh />,
    },
    {
      path: "/admin/request/:id",
      element: <AdminRequest />,
    },
    {
      path: "/sign/:id",
      element: (
        <>
          <Layout>
            <SignHamayesh />
          </Layout>
        </>
      ),
    },
    {
      path: "/hamayesh",
      element: (
        <>
          <Layout>
            <Hamayesh />
          </Layout>
        </>
      ),
    },
    {
      path: "/expo/:id",
      element: (
        <>
          <Layout>
            <Expo />
          </Layout>
        </>
      ),
    },
    {
      path: "/gallery/:id",
      element: (
        <>
          <Layout>
            <Gallery />
          </Layout>
        </>
      ),
    },
    {
      path: "/gallery",
      element: (
        <>
          <Layout>
            <AllGallery />
          </Layout>
        </>
      ),
    },
    {
      path: "/hamayesh/:id",
      element: (
        <>
          <Layout>
            <HamayeshDetail />
          </Layout>
        </>
      ),
    },
    {
      path: "*",
      element: (
        <>
          <Layout>
            <NotFound />
          </Layout>
        </>
      ),
    },
  ]);
  return (
    <>
      {/* <RouterProvider router={router} /> */}
      Internal Server Error
    </>
  );
}

export default App;
