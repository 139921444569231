import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { httpReauest } from "../api/api";
import Navbar from "../components/Navbar";
import * as Yup from "yup";
import { useFormik } from "formik";
import Alert from "../components/Alert";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
// import DatePicker from "react-datepicker";
import { BASE_URL } from "../config";
import momentPackage from "moment";
import DatePicker from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const CreateHmayesh = () => {
  const [cookies, setCookie] = useCookies(["admin"]);
  const [logined, setlogined] = useState(true);
  const [image, setimage] = useState(null);
  const [preview, setpreview] = useState(null);
  const [image2, setimage2] = useState(null);
  const [preview2, setpreview2] = useState(null);
  const [moment, setmoment] = useState(new Date());

  useEffect(() => {
    if (image) {
      if (image.size > 2031517) {
        toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        setimage(null);
      } else {
        setPreviwImage();
      }
    }
  }, [image]);

  useEffect(() => {
    if (image2) {
      if (image2.size > 2031517) {
        toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        setimage2(null);
      } else {
        setPreviwImage2();
      }
    }
  }, [image2]);

  function setPreviwImage2() {
    const reader = new FileReader();
    reader.onloadend = function () {
      setpreview2(reader.result);
    };
    reader.readAsDataURL(image2);
  }

  function setPreviwImage() {
    const reader = new FileReader();
    reader.onloadend = function () {
      setpreview(reader.result);
    };
    reader.readAsDataURL(image);
  }

  useEffect(() => {
    // stayLogin();
  }, []);

  // async function stayLogin() {
  //   await httpReauest(
  //     "POST",
  //     "/admin/islogined",
  //     {},
  //     { "x-auth-token": cookies?.admin }
  //   )
  //     .then(({ status }) => {
  //       if (status === 201) {
  //         setlogined(true);
  //       } else {
  //         window.location.href = window.location.origin;
  //       }
  //     })
  //     .catch((e) => {
  //       window.location.href = window.location.origin;
  //     });
  // }

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      location: Yup.string().required("الزامی است"),
      type: Yup.string().required("الزامی است"),
      date: Yup.string().required("الزامی است"),
      expo: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
      type: "",
      date: "",
      expo: "",
      giveSignCode: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const formData = new FormData();

      if (image) {
        formData.append("image", image);
      }
      if (image2) {
        formData.append("image2", image2);
      }
      formData.append("name", values.name);
      formData.append("expo", values.expo);
      formData.append("location", values.location);
      formData.append("date", values.date);
      formData.append("moment", moment);
      formData.append("type", values.type);
      formData.append("giveSignCode", values.giveSignCode);

      await fetch(BASE_URL + "/user/hamayesh_create", {
        method: "POST",
        body: formData,
      })
        .then((dat) => {
          if (dat?.status == 200) {
            setSuccess(true);
            formik.resetForm();
            setpreview(null);
            setpreview2(null);
          }
        })
        .catch((err) => {
          setErrors(err.response?.data?.message);
          formik.resetForm();
          setpreview(null);
          setpreview2(null);
        });
    },
  });

  console.log(formik.values);

  return (
    <>
      {logined ? (
        <div
          dir="rtl"
          style={{
            position: "relative",
            overflow: "visible",
            minHeight: "100vh",
          }}
          className="w-100 m-0 jumbotron  d-flex flex-wrap bg-dark pt-5 pt-md-0"
        >
          <Navbar />
          <div className="col-md-9 col-xl-10 px-0  pr-md-4">
            <div className="row m-0  p-3 w-100 ">
              <form
                onSubmit={formik.handleSubmit}
                className="w-100 d-flex flex-wrap px-4 px-lg-5"
              >
                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                  <label className="text-white">اسم همایش *</label>
                  <br />
                  <input
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-100 form-control mt-2"
                  />
                  {formik.errors.name && formik.touched.name && (
                    <small className="text-danger">{formik.errors.name}</small>
                  )}
                </span>
                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12 d-flex flex-column justify-content-start">
                  <label className="text-white mb-2">تاریخ برگزاری *</label>
                  <DatePicker
                    value={moment}
                    
                    onChange={(e) => {
                      formik.setFieldValue("date", e.toString());
                      setmoment(e.toDate());
                    }}
                    calendar={persian}
                    locale={persian_fa}
                  />
                  {formik.errors.moment && formik.touched.moment && (
                    <small className="text-danger">
                      {formik.errors.moment}
                    </small>
                  )}
                </span>
                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                  <label className="text-white">محل برگزاری *</label>
                  <br />
                  <input
                    id="location"
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-100 form-control mt-2"
                  />
                  {formik.errors.location && formik.touched.location && (
                    <small className="text-danger">
                      {formik.errors.location}
                    </small>
                  )}
                </span>
                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                  <label className="text-white">موضوع همایش</label>
                  <br />
                  <select
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-100 form-select mt-2"
                  >
                    <option></option>
                    <option value={"normal"}>همایش</option>
                    <option value={"sport"}>ورزشی تفریحی</option>
                    <option value={"education"}>اموزشی</option>
                    <option value={"view"}>نمایشگاهی</option>
                  </select>
                  {formik.errors.type && formik.touched.type && (
                    <small className="text-danger">{formik.errors.type}</small>
                  )}
                </span>
                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                  <label className="text-white">
                    همایش رزرو غرفه داشته باشد؟
                  </label>
                  <br />
                  <select
                    id="expo"
                    name="expo"
                    value={formik.values.expo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-100 form-select mt-2"
                  >
                    <option></option>
                    <option value={true}>بله</option>
                    <option value={false}>خیر</option>
                  </select>
                  {formik.errors.expo && formik.touched.expo && (
                    <small className="text-danger">{formik.errors.expo}</small>
                  )}
                </span>
                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                  <label className="text-white">کد ملی داخل فرم باشد ؟</label>
                  <br />
                  <select
                    id="giveSignCode"
                    name="giveSignCode"
                    value={formik.values.giveSignCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-100 form-select mt-2"
                  >
                    <option></option>
                    <option value={true}>بله</option>
                    <option value={false}>خیر</option>
                  </select>
                  {formik.errors.giveSignCode &&
                    formik.touched.giveSignCode && (
                      <small className="text-danger">
                        {formik.errors.giveSignCode}
                      </small>
                    )}
                </span>

                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                  <label
                    className="cursor-pointer relative w-100"
                    htmlFor="addImage"
                  >
                    <label className="text-white mb-2">پوستر *</label>
                    <br />
                    {preview ? (
                      <img
                        src={preview ? preview : null}
                        className="w-100 rounded object-contain"
                        alt="upload"
                      />
                    ) : (
                      <div className="w-100 rounded border p-5 d-flex justify-content-center align-items-center">
                        <FaPlus
                          size={25}
                          className="inset-0 absolute m-auto text-white "
                        />
                      </div>
                    )}
                  </label>
                  <input
                    onChange={(e) => {
                      setimage(e.target.files[0]);
                    }}
                    id="addImage"
                    className="hidden d-none"
                    type={"file"}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </span>
                <span className="col-lg-4 col-xl-3 col-md-6 p-3 col-12">
                  <label
                    className="cursor-pointer relative w-100"
                    htmlFor="addImage2"
                  >
                    <label className="text-white mb-2">
                      بنر صفحه پیش ثبت نام{" "}
                    </label>
                    <br />
                    {preview2 ? (
                      <img
                        src={preview2 ? preview2 : null}
                        className="w-100 rounded object-contain"
                        alt="upload"
                      />
                    ) : (
                      <div className="w-100 rounded border p-5 d-flex justify-content-center align-items-center">
                        <FaPlus
                          size={25}
                          className="inset-0 absolute m-auto text-white "
                        />
                      </div>
                    )}
                  </label>
                  <input
                    onChange={(e) => {
                      setimage2(e.target.files[0]);
                    }}
                    id="addImage2"
                    className="hidden d-none"
                    type={"file"}
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </span>
                <span className="col-12 p-3">
                  <button type="submit" className="btn btn-danger">
                    تایید
                  </button>
                </span>
                <span className="col-12 p-3">
                  {success && (
                    <Alert type={"success"} contnet={"همایش جدید ثبت شد"} />
                  )}
                  {errors && <Alert type={"error"} contnet={errors} />}
                </span>
              </form>
            </div>
          </div>
        </div>
      ) : (
        "loadnig..."
      )}
    </>
  );
};

export default CreateHmayesh;
