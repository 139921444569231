import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../api/api";
import { useState } from "react";
import Alert from "../components/Alert";
import { HamayeshJson } from "./HamayeshDetail";
import { useParams } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import Loading from "../components/Loading";

const SignHamayesh = () => {
  const [data, setData] = useState();
  const [error, seterror] = useState();
  const [loading, setloading] = useState();
  const { id } = useParams();

  useEffect(() => {
    getData(id);
  }, []);

  async function getData(query) {
    const data2 = await httpReauest("GET", `/user/hamayesh/${query}`, {}, {});
    setData(data2?.data?.data);
  }

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      prefix: Yup.string().required("الزامی است"),
      name: Yup.string().required("الزامی است"),
      lesson: Yup.string().required("الزامی است"),
      signCode: Yup.string()
        .min(9, "معتبر نیست")
        .max(10, "معتبر نیست")
        .required("الزامی است"),
      phone: Yup.string()
        .required("الزامی است")
        .matches(/^\d+$/, "شماره تلفن صحیح نیست")
        .length(11, "باید یازده رقم باشد"),
    });
  };
  const formik = useFormik({
    initialValues: {
      prefix: "",
      name: "",
      phone: "",
      company: "",
      phoneComapny: "",
      desc: "",
      nationalCode: "",
      signCode: "",
      lesson: "",
      email: "",
      withh: "",
      approve: false,
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      if (error) {
        return;
      }
      setloading(true);

      await httpReauest(
        "POST",
        "/user/hamayesh_user",
        { ...values, hamayeshId: id },
        {}
      )
        .then((dat) => {
          if (dat?.status == 200) {
            setSuccess(true);
            formik.resetForm();
            setloading(false);
          }
        })
        .catch((err) => {
          setErrors(err.response?.data?.message);
          formik.resetForm();
          setloading(false);
        });
    },
  });

  // function isValidIranianNationalCode(input) {
  //   if (
  //     input ==
  //     ("1111111111" ||
  //       "2222222222" ||
  //       "3333333333" ||
  //       "4444444444" ||
  //       "5555555555" ||
  //       "6666666666" ||
  //       "7777777777" ||
  //       "8888888888" ||
  //       "9999999999" ||
  //       "0000000000")
  //   ) {
  //     formik.setFieldError("nationalCode", "کد ملی صحیح نمی باشد");
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  function nationalCodeHandle(e) {
    const reg = /^(\d)\1*$/;

    if (e.length !== 10) {
      seterror("معتبر نیست");
    } else {
      if (
        e ==
        ("1111111111" ||
          "2222222222" ||
          "3333333333" ||
          "4444444444" ||
          "5555555555" ||
          "6666666666" ||
          "7777777777" ||
          "8888888888" ||
          "9999999999" ||
          "0000000000")
      ) {
        return seterror("معتبر نیست");
      }
      seterror(null);
    }
  }

  return (
    <div className="pt-7 bg-dark container-fluid header px-0">
      {data?.img2 && (
        <img
          src={CDN_BASE_URL + data?.img2}
          className="w-100"
          style={{
            maxHeight: "500px",
            objectFit: "contain",
            marginTop: "-14px",
          }}
        />
      )}

      <div className="w-100  bg-pattern2 py-4">
        <div className="z-10">
          <h3 className="text-center pb-4">پیش ثبت نام در {data?.name}</h3>

          <form
            onSubmit={formik.handleSubmit}
            className="w-100 d-flex flex-wrap px-4 px-lg-5"
          >
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>پیشوند *</label>
              <br />
              <select
                id="prefix"
                name="prefix"
                value={formik.values.prefix}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 mt-2 form-select"
              >
                <option></option>
                <option>جناب آقای</option>
                <option>سرکار خانم</option>
              </select>
              {formik.errors.prefix && formik.touched.prefix && (
                <small className="text-danger">{formik.errors.prefix}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>نام و نام خانوادگی *</label>
              <br />
              <input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.name && formik.touched.name && (
                <small className="text-danger">{formik.errors.name}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>ایمیل </label>
              <br />
              <input
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.email && formik.touched.email && (
                <small className="text-danger">{formik.errors.email}</small>
              )}
            </span>
            {data?.giveSignCode && (
              <span className="col-lg-3 col-md-6 p-3 col-12">
                <label>کد ملی *</label>
                <br />
                <input
                  id="nationalCode"
                  name="nationalCode"
                  value={formik.values.nationalCode}
                  onChange={(e) => {
                    formik.handleChange(e);
                    nationalCodeHandle(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  className="w-100 form-control mt-2"
                />
                {error && <small className="text-danger">{error}</small>}
              </span>
            )}
            {id == "6591276f79f8914990367996" && (
              <span className="col-lg-3 col-md-6 p-3 col-12">
                <label>تعداد همراهان </label>
                <br />
                <input
                  id="withh"
                  name="withh"
                  type="number"
                  value={formik.values.withh}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-100 form-control mt-2"
                />
                {formik.errors.withh && formik.touched.withh && (
                  <small className="text-danger">{formik.errors.withh}</small>
                )}
              </span>
            )}

            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>شماره همراه *</label>
              <br />
              <input
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.phone && formik.touched.phone && (
                <small className="text-danger">{formik.errors.phone}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>عنوان سازمان/شرکت/دانشگاه </label>
              <br />
              <input
                id="company"
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>رشته تحصیلی *</label>
              <br />
              <select
                id="lesson"
                name="lesson"
                value={formik.values.lesson}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-select mt-2"
              >
                <option></option>
                <option>عمران</option>
                <option>معماری</option>
                <option>تاسیسات برقی</option>
                <option>تاسیسات مکانیکی</option>
                <option>شهرسازی</option>
                <option>ترافیک</option>
                <option>نقشه برداری</option>
              </select>
              {formik.errors.lesson && formik.touched.lesson && (
                <small className="text-danger">{formik.errors.lesson}</small>
              )}
            </span>
            <span className="col-12 p-3">
              <label>مختصری از رزومه ، فعالیت ها ، پروژه ها </label>
              <br />
              <textarea
                id="desc"
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
            </span>

            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>
                کد عضویت نظام مهندسی *<br />
              </label>{" "}
              <br />
              <input
                id="signCode"
                name="signCode"
                value={formik.values.signCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.signCode && formik.touched.signCode && (
                <small className="text-danger pt-2">
                  {formik.errors.signCode}
                </small>
              )}
            </span>
            <span className=" p-3 col-12 d-flex align-items-start">
              <input
                id="approve"
                name="approve"
                type="checkbox"
                value={formik.values.approve}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="ms-2 mt-2"
                style={{ width: "1rem", height: "1rem" }}
              />
              <label className="pb-2 text-danger fw-bold fs-5">
                اينجانب درخواست دريافت گواهينامه دانشگاهي و شركت در پنل تخصصي را
                دارم ( هزينه صدور گواهينامه ٤٠٠ هزار تومان ميباشد)
              </label>

              {formik.errors.approve && formik.touched.approve && (
                <small className="text-danger pt-2">
                  {formik.errors.approve}
                </small>
              )}
            </span>
            <span className="col-12 p-3 d-flex justify-content-center">
              {loading ? (
                <div
                  id="spinner"
                  className="show bg-white d-flex align-items-center justify-content-center"
                >
                  <div
                    className="spinner-border text-primary"
                    style={{ width: "2rem", height: "2rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <button type="submit" className="btn btn-danger fs-4 px-4">
                  تایید
                </button>
              )}
            </span>
            <span className="col-12 p-3">
              {success && (
                <Alert
                  type={"success"}
                  contnet={
                    "اطلاعات شما با موفقیت ثبت شد و مورد بررسی قرار میگیرد"
                  }
                />
              )}
              {errors && <Alert type={"error"} contnet={errors} />}
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignHamayesh;
