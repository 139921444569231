// Spinner
const spinner = () => {
  setTimeout(() => {
    const spinnerElement = document.getElementById("spinner");
    if (spinnerElement) {
      spinnerElement.classList.remove("show");
    }
  }, 1000);
};

spinner();

// Sticky Navbar
window.addEventListener("scroll", () => {
  const navbar = document.querySelector(".nav-bar");
  if (window.scrollY > 45) {
    navbar.classList.add("sticky-top");
  } else {
    navbar.classList.remove("sticky-top");
  }
});

// Back to top button
window.addEventListener("scroll", () => {
  const backToTopBtn = document.querySelector(".back-to-top");
  if (window.scrollY > 300) {
    backToTopBtn.style.opacity = 0;
    backToTopBtn.style.transition = "opacity 0.25s ease";
    backToTopBtn.style.opacity = 1;
  } else {
    backToTopBtn.style.opacity = 1;
    backToTopBtn.style.transition = "opacity 0.25s ease";
    backToTopBtn.style.opacity = 0;
  }

  backToTopBtn.addEventListener("click", () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
});

// Initialize carousels
const initCarousels = () => {
  // header carousel code
  // testimonials carousel code
};

initCarousels();
