import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../api/api";
import { useState } from "react";
import Alert from "../components/Alert";
import { HamayeshJson } from "./HamayeshDetail";
import { useLocation, useParams } from "react-router-dom";

const Expo = () => {
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const [title, settitle] = useState();

  const [data, setData] = useState();
  const { id } = useParams();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    getData(id);
    settitle(query.get("title"));
  }, []);

  async function getData(query) {
    const data2 = await httpReauest("GET", `/user/hamayesh/${query}`, {}, {});
    setData(data2?.data?.data);
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      prefix: Yup.string().required("الزامی است"),
      name: Yup.string().required("الزامی است"),
      signCode: Yup.string()
        .min(9, "معتبر نیست")
        .max(10, "معتبر نیست")
        .required("الزامی است"),
      phone: Yup.string()
        .required("الزامی است")
        .matches(/^\d+$/, "شماره تلفن صحیح نیست")
        .length(11, "باید یازده رقم باشد"),
    });
  };
  const formik = useFormik({
    initialValues: {
      prefix: "",
      name: "",
      phone: "",
      company: "",
      phoneComapny: "",
      desc: "",
      email: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const data = await httpReauest(
        "POST",
        "/user/hamayesh_request",
        { ...values, hamayeshId: id },
        {}
      );

      if (data?.status == 200) {
        setSuccess(true);
        formik.resetForm();
      } else {
        setErrors(data?.data);
        formik.resetForm();
      }
    },
  });

  return (
    <div className="pt-7 bg-dark container-fluid header px-0">
      <div className="w-100  bg-pattern2 py-4">
        <div className="z-10">
          <h4 className="text-center pb-4">
            {title == "help"
              ? "ثبت درخواست حمایت مالی و معنوی"
              : `ثبت درخواست رزرو غرفه در ${data?.name}`}
          </h4>
          <form
            onSubmit={formik.handleSubmit}
            className="w-100 d-flex flex-wrap px-4 px-lg-5"
          >
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>پیشوند *</label>
              <br />
              <select
                id="prefix"
                name="prefix"
                value={formik.values.prefix}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 mt-2 form-select"
              >
                <option></option>
                <option>جناب آقای</option>
                <option>سرکار خانم</option>
              </select>
              {formik.errors.prefix && formik.touched.prefix && (
                <small className="text-danger">{formik.errors.prefix}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>نام و نام خانوادگی *</label>
              <br />
              <input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.name && formik.touched.name && (
                <small className="text-danger">{formik.errors.name}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>ایمیل </label>
              <br />
              <input
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.email && formik.touched.email && (
                <small className="text-danger">{formik.errors.email}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>شماره همراه *</label>
              <br />
              <input
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.phone && formik.touched.phone && (
                <small className="text-danger">{formik.errors.phone}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>عنوان سازمان/شرکت/دانشگاه </label>
              <br />
              <input
                id="company"
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
            </span>

            <span className="col-12 p-3">
              <label>مختصری از رزومه ، فعالیت ها ، پروژه ها *</label>
              <br />
              <textarea
                id="desc"
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
            </span>

            <span className="col-12 p-3 d-flex justify-content-center">
              <button type="submit" className="btn btn-danger fs-4 px-4">
                تایید
              </button>
            </span>
            <span className="col-12 p-3">
              {success && (
                <Alert
                  type={"success"}
                  contnet={
                    "اطلاعات شما با موفقیت ثبت شد به زودی با شما ارتباط برقرار میشود"
                  }
                />
              )}
              {errors && <Alert type={"error"} contnet={errors} />}
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Expo;
