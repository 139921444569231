import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { httpReauest } from "../api/api";
import { FaAngleLeft } from "react-icons/fa";
import { CDN_BASE_URL } from "../config";

SwiperCore.use([Autoplay]);
const Home = () => {
  const [data, setData] = useState([]);
  const [sponser, setsponser] = useState();
  const [ads, setads] = useState();
  const [videos, setvideos] = useState();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const data2 = await httpReauest("GET", `/user/hamayesh`, {}, {});
    setData(data2?.data?.data);

    const data3 = await httpReauest("GET", `/user/sponser`, {}, {});
    setsponser(data3?.data?.data);

    const data4 = await httpReauest("GET", `/user/ads`, {}, {});
    setads(data4?.data?.data);

    const data5 = await httpReauest("GET", `/user/video`, {}, {});
    setvideos(data5?.data?.data);
  }

  const ref = useRef();

  return (
    <>
      <div className="container-fluid header bg-dark pt-7 px-0   pb-0">
        <div className="row g-0 align-items-start justify-content-between flex-md-row bg-primary ">
          <div className="bg-pattern"></div>
          <div className="col-lg-3 p-3 mt-lg-5 text-center py-5 py-lg-0 order-2 order-lg-1 z-10">
            <h1 className="display-5 animated text-black fadeIn mb-4 text-center">
              روادید های پیش رو
            </h1>
            <div className="border rounded my-3 py-1 shadow bg-primary">
              <a
                href="/hamayesh/6591274679f8914990367994"
                className="animated fadeIn my-3 pb-3 fs-4 fw-bold text-center text-white  "
              >
                جشن روز مهندس <FaAngleLeft />
              </a>
            </div>
            <div className="border rounded my-3 py-1 shadow  bg-primary">
              <a
                href=""
                className="animated fadeIn my-3 pb-3 fs-4 fw-bold text-center text-white "
              >
                جشن روز معمار <FaAngleLeft />
              </a>
            </div>
          </div>
          <div className="col-lg-4 animated fadeIn p-0  order-1 order-lg-2 z-10">
            <Swiper
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
              }}
              loop={true}
            >
              {data?.map((e) => {
                if (!e.end) {
                  return (
                    <SwiperSlide className="d-flex justify-content-center">
                      <a
                        href={"/hamayesh/" + e._id}
                        className="owl-carousel-item bg-white relative"
                      >
                        <img
                          className="img-fluid p-0"
                          style={{ height: "590px" }}
                          src={CDN_BASE_URL + e.img}
                          alt=""
                        />
                      </a>
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          </div>
          <div className="col-lg-5 row m-0 order-3 z-10">
            <div dir="ltr" className="col-lg-7 p-2 m-0">
              <h3 className=" animated text-black mb-4 mb-lg-4 mt-2 fadeIn text-center">
                {sponser?.name}
              </h3>
              {sponser && (
                <Swiper
                  className="mySwiper height_500"
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  speed={2000}
                  simulateTouch={false}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    576: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                      direction: "vertical",
                    },
                    992: {
                      slidesPerView: 3,
                      direction: "vertical",
                    },
                  }}
                >
                  {sponser?.images?.map((src, i) => {
                    return (
                      <SwiperSlide className=" mx-2 mx-lg-0">
                        <div
                          key={i}
                          className="bg-white w-100 p-3 shadow shadow-lg rounded"
                          style={{ height: "150px" }}
                        >
                          <img
                            src={CDN_BASE_URL + src}
                            className="w-100 h-100"
                            style={{ objectFit: "contain" }}
                            alt=""
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
            <div className="col-lg-5 p-2 m-0">
              <img
                src={CDN_BASE_URL + ads?.img1}
                className="w-100"
                style={{ height: "240px" }}
                alt="ads"
              />

              <img
                src={CDN_BASE_URL + ads?.img2}
                className="w-100 mt-3"
                style={{ height: "300px" }}
                alt="ads"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div
              className="container-fluid bg-primary mb-5 wow fadeIn"
              data-wow-delay="0.1s"
              style={{ padding: "35px" }}
            >
              <div className="container">
                <div className="row g-2">
                  <div className="col-md-10">
                    <div className="row g-2">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control border-0 py-3"
                          placeholder="Search Keyword"
                        />
                      </div>
                      <div className="col-md-4">
                        <select className="form-select border-0 py-3">
                          <option defaultValue>Property Type</option>
                          <option value="1">Property Type 1</option>
                          <option value="2">Property Type 2</option>
                          <option value="3">Property Type 3</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select className="form-select border-0 py-3">
                          <option defaultValue>Location</option>
                          <option value="1">Location 1</option>
                          <option value="2">Location 2</option>
                          <option value="3">Location 3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="btn btn-dark border-0 w-100 py-3">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div> */}

      {/* <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="mb-3">تعرفه تبلیغات محیطی</h1>
            <p>یک توضیح برای تبلیغات محیطی میتواند اینجا قرار بگیره</p>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>بیلبورد راه بند ورودی (افقی 300 * 500)</h6>
                  <span>250.000.000 ريال</span>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>بیلبورد درب اختصاصی (افقی 300 * 500)</h6>
                  <span>250.000.000 ريال</span>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>بیلبورد ورودی چمران (افقی 300 * 500)</h6>
                  <span>300.000.000 ريال</span>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>بیلبورد پارکینگ اختصاصی (افقی 400 * 600)</h6>
                  <span>200.000.000 ريال</span>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>
                    استرابرد ورودی و مسیر درب اختصاصی هر عدد (عمودی 300 * 200)
                  </h6>
                  <span>150.000.000 ريال</span>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>استند ژاپنی مسیر ورودی (عمودی 300 * 100)</h6>
                  <span>100.000.000 ريال</span>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>
                    لایت باکس راهروی سالن نمایشگاه A به سالن B (افقی 244 * 122)
                  </h6>
                  <span>200.000.000 ريال</span>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a
                className="cat-item d-block bg-light text-center rounded p-3"
                href=""
              >
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <img
                      className="img-fluid"
                      src="/img/icon-apartment.png"
                      alt="Icon"
                    />
                  </div>
                  <h6>لایت باکس در دو طرف سن اصلی (90 * 2)</h6>
                  <span>1.200.000.000 ريال</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}

      <div
        style={{
          borderBottom: "40px solid #efac25",
        }}
        className="container-xxl py-5 bg-pattern2"
      >
        <div className="container ">
          <div className="row g-0 gx-5 align-items-end">
            <div className="w-100">
              <div
                className="text-center mx-auto mb-5 wow slideInLeft"
                data-wow-delay="0.1s"
              >
                <h2 className="mb-3 fw-bold number_farsi">{videos?.name1}</h2>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div id="tab-1" className="tab-pane fade show p-0 active">
              <div className="row g-4">
                <div
                  className="w-100 wow fadeInUp d-flex justify-content-center"
                  data-wow-delay="0.1s"
                >
                  <div className="col-lg-6 pb-4">
                    <video
                      className="img-fluid rounded shadow"
                      src={CDN_BASE_URL + videos?.video1}
                      controls
                      alt=""
                    />
                  </div>
                </div>

                {/* <div
                  className="col-lg-4 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="property-item rounded overflow-hidden">
                    <div className="position-relative overflow-hidden">
                      <a href="">
                        <img
                          className="img-fluid"
                          src="/img/property-1.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="p-4 pb-0">
                      <a className="d-block h5 mb-2" href="">
                        موضوع عکس
                      </a>
                      <p>توضیح عکس</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ borderBottom: "40px solid #efac25" }}
        className="container-xxl py-5 bg-pattern2"
      >
        <div className="container">
          <div className="row g-0 gx-5 align-items-end">
            <div className="w-100">
              <div
                className="text-center mx-auto mb-5 wow slideInLeft"
                data-wow-delay="0.1s"
              >
                <h2 className="mb-3 number_farsi fw-bold"> {videos?.name2}</h2>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div id="tab-1" className="tab-pane fade show p-0 active">
              <div className="row g-4">
                <div
                  className="w-100 wow fadeInUp d-flex justify-content-center"
                  data-wow-delay="0.1s"
                >
                  <div className="col-lg-6 pb-4">
                    <video
                      className="img-fluid rounded shadow"
                      src={CDN_BASE_URL + videos?.video2}
                      controls
                      alt=""
                    />
                  </div>
                </div>

                {/* <div
                  className="col-lg-4 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="property-item rounded overflow-hidden">
                    <div className="position-relative overflow-hidden">
                      <a href="">
                        <img
                          className="img-fluid"
                          src="/img/property-1.jpg"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="p-4 pb-0">
                      <a className="d-block h5 mb-2" href="">
                        موضوع عکس
                      </a>
                      <p>توضیح عکس</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img position-relative overflow-hidden p-5 pe-0">
                <img className="img-fluid w-100" src="/about.jpg" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">درباره گروه شمع</h1>
              <h5 className="mb-4">
                اهداف و برنامه ها در نهمین دوره هیات مدیره (خدمات مهندسی)
              </h5>
              <p>
                <strong>تعرفه خدمات مهندسی</strong>
                <br />
                تعرفه خدمات مهندسان براساس قیمت واقعی ساخت و ساز ساختمان منبعث
                از مبحث ۲ مقررات ملی ساختمان از طریق فهرست بهای سازمان مدیریت
                تدوین و در نهایت پس از نظرسنجی از اعضاء به وزارت راه و شهرسازی
                ارائه و تا حصول نتیجه پیگیری گردد.
              </p>
              <p>
                <strong>اصلاح توزیع عادلانه کار بین اعضای سازمان</strong>
                <br />
                1- تلاش برای تنسیق خدمات مهندسی و توزیع عادلانه کار بین اعضای
                حقیقی و حقوقی از جمله اختصاص سهمی از طراحی پروژه ها صرفا از طریق
                مهندسان حقیقی سازمان
                <br />
                2- احیای ارجاع نظارت برای شرکتهای حقوقی <br />
                3- تلاش جهت حذف خدمات صوری
              </p>
              <a className="btn btn-primary py-3 px-5 mt-3" href="">
                درباره ما
              </a>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="mb-3">نمایندگان گروه شمع</h1>
            <p>یک توضیح</p>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="/n1.jpg" alt="" />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">الهام شادبهر</h5>
                  <small>رشته عمران</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="/n2.jpg" alt="" />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a className="btn btn-square mx-1" href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">بیژن خطیبی</h5>
                  <small>رشته عمران</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-xxl py-5 bg-pattern2">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h2 className="mb-3 number_farsi fw-bold">نظرات اعضای گروه</h2>
          </div>
          <div className="wow fadeInUp px-lg-5" data-wow-delay="0.1s">
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 2000 }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2,
                },
              }}
              loop={true}
            >
              <SwiperSlide>
                <div className="testimonial-item bg-light rounded p-3">
                  <div className="bg-white border rounded p-4">
                    <p> در حال حاضر منتظر نظرات شما هستیم</p>
                    <div className="d-flex align-items-center">
                      <img
                        className="img-fluid flex-shrink-0 rounded"
                        src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png"
                        style={{ width: "45px", height: "45px" }}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-item bg-light rounded p-3">
                  <div className="bg-white border rounded p-4">
                    <p> در حال حاضر منتظر نظرات شما هستیم</p>
                    <div className="d-flex align-items-center">
                      <img
                        className="img-fluid flex-shrink-0 rounded"
                        src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png"
                        style={{ width: "45px", height: "45px" }}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonial-item bg-light rounded p-3">
                  <div className="bg-white border rounded p-4">
                    <p> در حال حاضر منتظر نظرات شما هستیم</p>
                    <div className="d-flex align-items-center">
                      <img
                        className="img-fluid flex-shrink-0 rounded"
                        src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png"
                        style={{ width: "45px", height: "45px" }}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
