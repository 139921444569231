import React, { useEffect, useState } from "react";
import { httpReauest } from "../api/api";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import Navbar from "../components/Navbar";
import { BASE_URL, CDN_BASE_URL } from "../config";
import Alert from "../components/Alert";
import { FaPlus, FaTrash } from "react-icons/fa";

const Ads = () => {
  const [cookies, setCookie] = useCookies(["admin"]);
  const [logined, setlogined] = useState();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const [images, setImages] = useState([]);
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [previews, setPreview] = useState([]);
  const [preview1, setPreview1] = useState([]);
  const [preview2, setPreview2] = useState([]);

  useEffect(() => {
    stayLogin();
    getSponsers();
  }, []);

  async function stayLogin() {
    await httpReauest(
      "POST",
      "/admin/islogined",
      {},
      { "x-auth-token": cookies?.admin }
    )
      .then(({ status }) => {
        if (status === 201) {
          setlogined(true);
        } else {
          window.location.href = window.location.origin;
        }
      })
      .catch((e) => {
        window.location.href = window.location.origin;
      });
  }

  async function getSponsers() {
    const data = await httpReauest("GET", "/user/ads", {}, {});

    const newHamayesh = data.data.data;

    const response = await fetch(CDN_BASE_URL + newHamayesh?.img1);
    const blob = await response.blob();
    const file = new File([blob], newHamayesh?.img1, {
      type: blob.type,
    });
    handleChangeImg1(file);

    const response2 = await fetch(CDN_BASE_URL + newHamayesh?.img2);
    const blob2 = await response2.blob();
    const file2 = new File([blob2], newHamayesh?.img2, {
      type: blob2.type,
    });
    handleChangeImg2(file2);
  }

  async function hadnleSend(params) {
    const formdata = new FormData();

    formdata.append("image", image1);
    formdata.append("image2", image2);

    await fetch(BASE_URL + "/user/ads/", {
      method: "POST",
      body: formdata,
    })
      .then((dat) => {
        if (dat?.status == 200) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        setErrors(err.response?.data?.message);
      });
  }

  function handleChangeImgFromInput1(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImage1(fils[index]);
          setPreviwImage1(fils[index]);
        }
      }
    }
  }

  function handleChangeImgFromInput2(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImage2(fils[index]);
          setPreviwImage2(fils[index]);
        }
      }
    }
  }

  async function handleChangeImg1(e) {
    if (e.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImage1(e);
      setPreviwImage1(e);
    }
  }

  async function handleChangeImg2(e) {
    if (e.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImage2(e);
      setPreviwImage2(e);
    }
  }

  async function setPreviwImage1(img) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(img);
  }

  async function setPreviwImage2(img) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(img);
  }

  async function setPreviwImage(img) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(img);
  }

  async function handleDelete(image) {
    const newPreviews = previews.filter((e) => e !== image);
    setPreview(newPreviews);

    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], image, { type: blob.type });

    const newImages = images.filter((i) => i.size !== blob.size);
    setImages(newImages);
  }

  return (
    <>
      {logined ? (
        <div
          dir="rtl"
          style={{
            position: "relative",
            overflow: "visible",
            minHeight: "100vh",
          }}
          className="w-100 m-0 jumbotron  d-flex flex-wrap bg-dark pt-5 pt-md-0"
        >
          <Navbar />
          <div className="col-md-9 col-xl-10 px-0  pr-md-4">
            <div className="row m-0  p-3 w-100 ">
              <form className="w-100 d-flex flex-wrap px-4 px-lg-5">
                <>
                  <span className=" p-3 col-12">
                    <label className="text-white">تبلیغات</label>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "15px",
                      }}
                    >
                      {preview1 && (
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          <img
                            src={preview1}
                            style={{
                              width: "300px",
                              height: "300px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                          <label htmlFor="addImage1">
                            <FaPlus
                              style={{
                                position: "absolute",
                                top: "45%",
                                right: "45%",
                                color: "white",
                                cursor: "pointer",
                                background: "#000",
                                padding: "5px",
                              }}
                              size={30}
                            />
                          </label>

                          <input
                            onChange={(e) => {
                              handleChangeImgFromInput1(e);
                            }}
                            style={{ display: "none" }}
                            id="addImage1"
                            type={"file"}
                            accept="image/png, image/jpg, image/jpeg, image/gif"
                          />
                        </span>
                      )}
                      {preview2 && (
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          <img
                            src={preview2}
                            style={{
                              width: "300px",
                              height: "300px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                          <label htmlFor="addImage2">
                            <FaPlus
                              style={{
                                position: "absolute",
                                top: "45%",
                                right: "45%",
                                color: "white",
                                cursor: "pointer",
                                background: "#000",
                                padding: "5px",
                              }}
                              size={30}
                            />
                          </label>

                          <input
                            onChange={(e) => {
                              handleChangeImgFromInput2(e);
                            }}
                            style={{ display: "none" }}
                            id="addImage2"
                            type={"file"}
                            accept="image/png, image/jpg, image/jpeg, image/gif"
                          />
                        </span>
                      )}
                    </div>
                  </span>
                  <button
                    type="button"
                    onClick={hadnleSend}
                    className="btn btn-danger mx-2"
                  >
                    تایید
                  </button>
                </>
                <span className="col-12 p-3">
                  {success && (
                    <Alert
                      type={"success"}
                      contnet={"عکس ها با موفقیت اپلود شدند"}
                    />
                  )}
                  {errors && <Alert type={"error"} contnet={errors} />}
                </span>
              </form>
            </div>
          </div>
        </div>
      ) : (
        "loadnig..."
      )}
    </>
  );
};

export default Ads;
