import React, { useEffect, useState } from "react";
import { httpReauest } from "../api/api";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import Navbar from "../components/Navbar";
import { BASE_URL, CDN_BASE_URL } from "../config";
import Alert from "../components/Alert";
import { FaPlus, FaTrash } from "react-icons/fa";

const Video = () => {
  const [cookies, setCookie] = useCookies(["admin"]);
  const [logined, setlogined] = useState();
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const [images, setImages] = useState([]);
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [previews, setPreview] = useState([]);
  const [preview1, setPreview1] = useState([]);
  const [preview2, setPreview2] = useState([]);
  const [name1, setname1] = useState([]);
  const [name2, setname2] = useState([]);

  useEffect(() => {
    stayLogin();
    getSponsers();
  }, []);

  async function stayLogin() {
    await httpReauest(
      "POST",
      "/admin/islogined",
      {},
      { "x-auth-token": cookies?.admin }
    )
      .then(({ status }) => {
        if (status === 201) {
          setlogined(true);
        } else {
          window.location.href = window.location.origin;
        }
      })
      .catch((e) => {
        window.location.href = window.location.origin;
      });
  }

  async function getSponsers() {
    const data = await httpReauest("GET", "/user/video", {}, {});

    const newHamayesh = data.data.data;

    setname1(newHamayesh?.name1);
    setname2(newHamayesh?.name2);

    const response = await fetch(CDN_BASE_URL + newHamayesh?.video1);
    const blob = await response.blob();
    const file = new File([blob], newHamayesh?.video1, {
      type: blob.type,
    });
    handleChangeImg1(file);

    const response2 = await fetch(CDN_BASE_URL + newHamayesh?.video2);
    const blob2 = await response2.blob();
    const file2 = new File([blob2], newHamayesh?.video2, {
      type: blob2.type,
    });
    handleChangeImg2(file2);
  }

  console.log(name1);

  async function hadnleSend(params) {
    const formdata = new FormData();

    formdata.append("image", image1);
    formdata.append("image2", image2);
    formdata.append("name1", name1);
    formdata.append("name2", name2);

    await fetch(BASE_URL + "/user/video/", {
      method: "POST",
      body: formdata,
    })
      .then((dat) => {
        if (dat?.status == 200) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        setErrors(err.response?.data?.message);
      });
  }

  function handleChangeImgFromInput1(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils.length; index++) {
        setImage1(fils[index]);
        setPreviwImage1(fils[index]);
      }
    }
  }

  function handleChangeImgFromInput2(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils.length; index++) {
        setImage2(fils[index]);
        setPreviwImage2(fils[index]);
      }
    }
  }

  async function handleChangeImg1(e) {
    setImage1(e);
    setPreviwImage1(e);
  }

  async function handleChangeImg2(e) {
    setImage2(e);
    setPreviwImage2(e);
  }

  async function setPreviwImage1(img) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(img);
  }

  async function setPreviwImage2(img) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(img);
  }

  return (
    <>
      {logined ? (
        <div
          dir="rtl"
          style={{
            position: "relative",
            overflow: "visible",
            minHeight: "100vh",
          }}
          className="w-100 m-0 jumbotron  d-flex flex-wrap bg-dark pt-5 pt-md-0"
        >
          <Navbar />
          <div className="col-md-9 col-xl-10 px-0  pr-md-4">
            <div className="row m-0  p-3 w-100 ">
              <form className="w-100 d-flex flex-wrap px-4 px-lg-5">
                <>
                  <span className=" p-3 col-12">
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "15px",
                      }}
                    >
                      <span className="col-lg-3 col-md-6 col-12">
                        <label className="text-white">تیتر ویدیو اول:</label>
                        <input
                          id="name1"
                          name="name1"
                          value={name1}
                          onChange={(e) => setname1(e.target.value)}
                          className="w-100 form-control mt-2"
                        />
                      </span>
                      <span className="col-lg-3 col-md-6 col-12">
                        <label className="text-white">تیتر ویدیو دوم:</label>
                        <input
                          id="name2"
                          name="name2"
                          value={name2}
                          onChange={(e) => setname2(e.target.value)}
                          className="w-100 form-control mt-2"
                        />
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "15px",
                      }}
                    >
                      {preview1 && (
                        <div
                          style={{ position: "relative" }}
                          className="col-lg-3 col-md-6 col-12 mt-3"
                        >
                          <label className="text-white">ویدیو اول:</label>
                          <video
                            src={preview1}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            alt=""
                          />
                          <label htmlFor="addImage1">
                            <FaPlus
                              style={{
                                position: "absolute",
                                top: "55%",
                                right: "45%",
                                color: "white",
                                cursor: "pointer",
                                background: "#000",
                                padding: "5px",
                              }}
                              size={30}
                            />
                          </label>

                          <input
                            onChange={(e) => {
                              handleChangeImgFromInput1(e);
                            }}
                            style={{ display: "none" }}
                            id="addImage1"
                            type={"file"}
                            accept="video/mp4"
                          />
                        </div>
                      )}
                      {preview2 && (
                        <div
                          style={{ position: "relative" }}
                          className="col-lg-3 col-md-6 col-12 mt-3"
                        >
                          <label className="text-white">ویدیو دوم:</label>

                          <video
                            src={preview2}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            alt=""
                          />
                          <label htmlFor="addImage2">
                            <FaPlus
                              style={{
                                position: "absolute",
                                top: "55%",
                                right: "45%",
                                color: "white",
                                cursor: "pointer",
                                background: "#000",
                                padding: "5px",
                              }}
                              size={30}
                            />
                          </label>

                          <input
                            onChange={(e) => {
                              handleChangeImgFromInput2(e);
                            }}
                            style={{ display: "none" }}
                            id="addImage2"
                            type={"file"}
                            accept="video/mp4"
                          />
                        </div>
                      )}
                    </div>
                  </span>
                  <button
                    type="button"
                    onClick={hadnleSend}
                    className="btn btn-danger mx-2"
                  >
                    تایید
                  </button>
                </>
                <span className="col-12 p-3">
                  {success && (
                    <Alert
                      type={"success"}
                      contnet={"ویدیو ها با موفقیت اپلود شدند"}
                    />
                  )}
                  {errors && <Alert type={"error"} contnet={errors} />}
                </span>
              </form>
            </div>
          </div>
        </div>
      ) : (
        "loadnig..."
      )}
    </>
  );
};

export default Video;
