import React, { useEffect, useState } from "react";
import { FaAlignJustify, FaAngleLeft } from "react-icons/fa";
import { httpReauest } from "../api/api";

const Navbar = () => {
  const [show, setshow] = useState();
  const [data, setdata] = useState();

  function handleShow() {
    show ? setshow(false) : setshow(true);
  }

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const data = await httpReauest("GET", "/user/hamayesh");
    setdata(data.data?.data);
  }

  return (
    <>
      <div
        style={{
          background: "#000",
          position: "fixed",
          top: "0",
          zIndex: "10",
          right: "0",
        }}
        className="w-100 p-3 d-flex d-md-none"
      >
        <FaAlignJustify onClick={handleShow} size={30} className="text-white" />
      </div>
      {show && (
        <div
          style={{
            position: "fixed",
            top: "58px",
            left: "0",
            zIndex: "12",
          }}
          className=" bg-primary w-100 shadow-lg p-3 text-right  flex-column "
        >
          <a
            href={"/admin/dashboard"}
            className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
          >
            کاربران بخش عضویت <FaAngleLeft />
          </a>
          {data?.map((e, i) => {
            return (
              <div key={i}>
                <a
                  href={"/admin/hamayesh/" + e?._id}
                  className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
                >
                  پیش ثبت نام {e?.name} <FaAngleLeft />
                </a>
                <a
                  href={"/admin/request/" + e?._id}
                  className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
                >
                  درخواست های {e?.name} <FaAngleLeft />
                </a>
              </div>
            );
          })}
          <a
            href={"/admin/dashboard/create"}
            className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
          >
            ایجاد همایش <FaAngleLeft />
          </a>
          <a
            href={"/admin/dashboard/edit"}
            className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
          >
            تغییر همایش <FaAngleLeft />
          </a>
          <a
            href={"/admin/dashboard/gallery"}
            className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
          >
            آپلود عکس برای همایش
            <FaAngleLeft />
          </a>
          <a
            href={"/admin/dashboard/sponser"}
            className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
          >
            اسپانسر ها
            <FaAngleLeft />
          </a>
          <a
            href={"/admin/dashboard/ads"}
            className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
          >
            تبلیغات
            <FaAngleLeft />
          </a>
          <a
            href={"/admin/dashboard/video"}
            className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
          >
            مدیدریت ویدیو های صفحه اول
            <FaAngleLeft />
          </a>
        </div>
      )}
      <div
        style={{ background: "#00000095", position: "sticky", top: "0" }}
        className="col-md-3 col-xl-2 shadow-lg p-3 text-right h-100 rounded-4 navbar d-none d-md-block"
      >
        <a
          href={"/admin/dashboard"}
          className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
        >
          کاربران بخش عضویت <FaAngleLeft />
        </a>
        {data?.map((e, i) => {
          return (
            <div key={i}>
              <a
                href={"/admin/hamayesh/" + e?._id}
                className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
              >
                پیش ثبت نام {e?.name} <FaAngleLeft />
              </a>
              <a
                href={"/admin/request/" + e?._id}
                className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
              >
                درخواست های {e?.name} <FaAngleLeft />
              </a>
            </div>
          );
        })}
        <a
          href={"/admin/dashboard/create"}
          className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
        >
          ایجاد همایش <FaAngleLeft />
        </a>
        <a
          href={"/admin/dashboard/edit"}
          className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
        >
          تغییر همایش <FaAngleLeft />
        </a>
        <a
          href={"/admin/dashboard/gallery"}
          className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
        >
          آپلود عکس برای همایش
          <FaAngleLeft />
        </a>
        <a
          href={"/admin/dashboard/sponser"}
          className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
        >
          اسپانسر ها
          <FaAngleLeft />
        </a>
        <a
          href={"/admin/dashboard/ads"}
          className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
        >
          تبلیغات
          <FaAngleLeft />
        </a>
        <a
          href={"/admin/dashboard/video"}
          className=" fs-6 d-flex align-items-center py-2 border-bottom justify-content-between text-white"
        >
          مدیدریت ویدیو های صفحه اول
          <FaAngleLeft />
        </a>
      </div>
    </>
  );
};

export default Navbar;
