import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { httpReauest } from "../api/api";
import { CDN_BASE_URL } from "../config";

const Invite = () => {
  const [data, setData] = useState();
  const [hamayeshdata, sethamayeshdata] = useState();
  const { id, hamayeshId, vip } = useParams();
  useEffect(() => {
    getData(id, hamayeshId);
  }, []);

  async function getData(query, query2) {
    const data2 = await httpReauest("GET", `/user/${query}`, {}, {});
    const data3 = await httpReauest("GET", `/user/hamayesh/${query2}`, {}, {});
    setData(data2?.data?.data);
    sethamayeshdata(data3?.data?.data);
  }

  console.log(hamayeshdata);

  return (
    <span style={{ position: "relative" }}>
      <img
        src={
          vip === "true"
            ? CDN_BASE_URL + hamayeshdata?.inviteVip
            : CDN_BASE_URL + hamayeshdata?.invite
        }
        style={{ width: "440px", minWidth: "440px" }}
        alt="invite"
      />
      <h5
        style={{
          position: "absolute",
          top: "-135px",
          right: "180px",
          fontSize: "18px",
        }}
      >
        {data?.name}
      </h5>
    </span>
  );
};

export default Invite;
