import React, { useEffect, useState } from "react";
import { HamayeshJson } from "./HamayeshDetail";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { httpReauest } from "../api/api";
import Countdown from "react-countdown";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { CDN_BASE_URL } from "../config";

const Hamayesh = () => {
  const [data, setData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [imageSrc, setImagSrc] = React.useState(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  useEffect(() => {
    getData(query.get("type") ? query.get("type") : "normal");
  }, []);

  async function getData(query) {
    const data2 = await httpReauest(
      "GET",
      `/user/hamayesh?type=${query}`,
      {},
      {}
    );
    setData(data2?.data?.data);
  }

  function openModal(src) {
    setIsOpen(true);
    setImagSrc(src);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    return (
      <span className="bg-success p-3 d-flex w-100 rounded">
        <span className="col-6 d-flex flex-column justify-content-center align-items-center">
          <h1 className="display-2 m-0 text-white">
            {hours < 10 ? "0" + hours : hours}
          </h1>
          <h5 className="text-warning">ساعت</h5>
        </span>
        <span className="col-6 d-flex flex-column justify-content-center align-items-center">
          <h1 className="display-2 m-0 text-white">
            {days < 10 ? "0" + days : days}
          </h1>
          <h5 className="text-warning">روز</h5>
        </span>
      </span>
    );
  };

  return (
    <div className="pt-7 bg-dark container-fluid header px-0 ">
      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <img className="w-100" src={imageSrc} alt="modal" />
      </Modal>
      <div className="bg-pattern2 pt-5">
        <div className="z-10">
          <h3 className="text-center text-black">برنامه های پیش رو گروه شمع</h3>
          <div className="row pt-4 px-3 justify-content-center">
            {data?.map((data) => {
              return (
                <div className="col-lg-3 col-md-6 col-12 p-3">
                  <div className="d-flex flex-column align-items-center">
                    <img
                      style={{ height: "400px", cursor: "pointer" }}
                      className="w-100 rounded border border-primary"
                      src={CDN_BASE_URL + data?.img}
                      alt={data?.name}
                      onClick={() => openModal(CDN_BASE_URL + data?.img)}
                    />
                    {data.end ? (
                      <>
                        <h6 className="pt-4 pb-3 text-center">برگزار شد</h6>
                        <a
                          href={"/hamayesh/" + data._id}
                          className="btn btn-primary mt-4"
                        >
                          مرور روادید
                        </a>
                      </>
                    ) : (
                      <>
                        <h6 className="pt-4 pb-3 text-center">
                          زمان باقی مانده
                        </h6>
                        <Countdown date={data?.moment} renderer={renderer} />

                        <a
                          href={"/hamayesh/" + data._id}
                          className="btn btn-primary mt-3"
                        >
                          اطلاعات بیشتر
                        </a>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hamayesh;
