import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../api/api";
import { useState } from "react";
import Alert from "../components/Alert";

const Sign = () => {
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      prefix: Yup.string().required("الزامی است"),
      name: Yup.string().required("الزامی است"),
      email: Yup.string().required("الزامی است"),
      lesson: Yup.string().required("الزامی است"),
      nationalCode: Yup.string().required("الزامی است"),
      desc: Yup.string().required("الزامی است"),
      company: Yup.string().required("الزامی است"),
      signCode: Yup.string()
        .min(9, "معتبر نیست")
        .max(10, "معتبر نیست")
        .required("الزامی است"),
      phone: Yup.string()
        .required("الزامی است")
        .matches(/^\d+$/, "شماره تلفن صحیح نیست")
        .length(11, "باید یازده رقم باشد"),
    });
  };
  const formik = useFormik({
    initialValues: {
      prefix: "",
      name: "",
      phone: "",
      company: "",
      phoneComapny: "",
      desc: "",
      nationalCode: "",
      signCode: "",
      lesson: "",
      email: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const data = await httpReauest("POST", "/user", values, {});

      if (data?.status == 200) {
        setSuccess(true);
        formik.resetForm();
      } else {
        setErrors(data?.data);
        formik.resetForm();
      }
    },
  });

  return (
    <div className="pt-6 bg-dark container-fluid header px-0">
      <img
        src="https://cbx-prod.b-cdn.net/COLOURBOX26402161.jpg?width=1600&quality=90"
        className="w-100"
      />
      <div className="w-100  bg-pattern2 py-4">
        <div className="z-10">
          <h3 className="text-center pb-4">عضویت در گروه شمع</h3>

          <form
            onSubmit={formik.handleSubmit}
            className="w-100 d-flex flex-wrap px-4 px-lg-5"
          >
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>پیشوند *</label>
              <br />
              <select
                id="prefix"
                name="prefix"
                value={formik.values.prefix}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 mt-2 form-select"
              >
                <option></option>
                <option>جناب آقای</option>
                <option>سرکار خانم</option>
              </select>
              {formik.errors.prefix && formik.touched.prefix && (
                <small className="text-danger">{formik.errors.prefix}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>نام و نام خانوادگی *</label>
              <br />
              <input
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.name && formik.touched.name && (
                <small className="text-danger">{formik.errors.name}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>ایمیل *</label>
              <br />
              <input
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.email && formik.touched.email && (
                <small className="text-danger">{formik.errors.email}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>کد ملی *</label>
              <br />
              <input
                id="nationalCode"
                name="nationalCode"
                value={formik.values.nationalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.nationalCode && formik.touched.nationalCode && (
                <small className="text-danger">
                  {formik.errors.nationalCode}
                </small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>شماره همراه *</label>
              <br />
              <input
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.phone && formik.touched.phone && (
                <small className="text-danger">{formik.errors.phone}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>عنوان سازمان/شرکت/دانشگاه *</label>
              <br />
              <input
                id="company"
                name="company"
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.company && formik.touched.company && (
                <small className="text-danger">{formik.errors.company}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>رشته تحصیلی *</label>
              <br />
              <select
                id="lesson"
                name="lesson"
                value={formik.values.lesson}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-select mt-2"
              >
                <option></option>
                <option>عمران</option>
                <option>معماری</option>
                <option>تاسیسات برقی</option>
                <option>تاسیسات مکانیکی</option>
                <option>شهرسازی</option>
                <option>ترافیک</option>
                <option>نقشه برداری</option>
              </select>
              {formik.errors.lesson && formik.touched.lesson && (
                <small className="text-danger">{formik.errors.lesson}</small>
              )}
            </span>
            <span className="col-12 p-3">
              <label>مختصری از رزومه ، فعالیت ها ، پروژه ها *</label>
              <br />
              <textarea
                id="desc"
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.desc && formik.touched.desc && (
                <small className="text-danger">{formik.errors.desc}</small>
              )}
            </span>
            <span className="col-lg-3 col-md-6 p-3 col-12">
              <label>
                کد عضویت نظام مهندسی *<br />
              </label>{" "}
              <br />
              <input
                id="signCode"
                name="signCode"
                value={formik.values.signCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-100 form-control mt-2"
              />
              {formik.errors.signCode && formik.touched.signCode && (
                <small className="text-danger mt-2">
                  {formik.errors.signCode}
                </small>
              )}
            </span>
            <span className="col-12 p-3">
              <button type="submit" className="btn btn-danger">
                تایید
              </button>
            </span>
            <span className="col-12 p-3">
              {success && (
                <Alert
                  type={"success"}
                  contnet={
                    "اطلاعات شما با موفقیت ثبت شد به زودی با شما ارتباط برقرار میشود"
                  }
                />
              )}
              {errors && <Alert type={"error"} contnet={errors} />}
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Sign;
