import React, { useEffect, useState } from "react";
import { httpReauest } from "../api/api";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import Navbar from "../components/Navbar";
import { BASE_URL, CDN_BASE_URL } from "../config";
import Alert from "../components/Alert";
import { FaPlus, FaTrash } from "react-icons/fa";

const UploadPhoto = () => {
  const [cookies, setCookie] = useCookies(["admin"]);
  const [logined, setlogined] = useState();
  const [hamayeshes, sethamayeshes] = useState(null);
  const [selectedHamayesh, setselectedHamayesh] = useState(null);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest(
      "POST",
      "/admin/islogined",
      {},
      { "x-auth-token": cookies?.admin }
    )
      .then(({ status }) => {
        if (status === 201) {
          setlogined(true);
        } else {
          window.location.href = window.location.origin;
        }
      })
      .catch((e) => {
        window.location.href = window.location.origin;
      });

    await httpReauest("GET", "/user/hamayesh", {}, {}).then(({ data }) => {
      sethamayeshes(data?.data);
    });
  }

  async function handleChange(e) {
    const newHamayesh = await hamayeshes.find(
      (value) => value._id == e.target.value
    );

    newHamayesh?.images.map(async (img) => {
      const response = await fetch(CDN_BASE_URL + img);
      const blob = await response.blob();
      const file = new File([blob], img, { type: blob.type });
      handleChangeImg2(file);
    });
    setselectedHamayesh(newHamayesh);
  }

  async function hadnleSend(params) {
    const formdata = new FormData();

    for (let i = 0; i < images.length; i++) {
      formdata.append("image", images[i]);
    }

    await fetch(BASE_URL + "/user/hamayesh_gallery/" + selectedHamayesh._id, {
      method: "POST",
      body: formdata,
    })
      .then((dat) => {
        if (dat?.status == 200) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        setErrors(err.response?.data?.message);
      });
  }

  function handleChangeImg(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImage(fils[index]);
        }
      }
    }
  }

  function handleChangeImg2(e) {
    if (e.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImages((current) => [...current, e]);
      setPreviwImage(e);
    }
  }

  async function setPreviwImage(img) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(img);
  }

  async function handleDelete(image) {
    const newPreviews = previews.filter((e) => e !== image);
    setPreview(newPreviews);

    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], image, { type: blob.type });

    const newImages = images.filter((i) => i.size !== blob.size);
    console.log(newImages);
    setImages(newImages);
  }

  return (
    <>
      {logined ? (
        <div
          dir="rtl"
          style={{
            position: "relative",
            overflow: "visible",
            minHeight: "100vh",
          }}
          className="w-100 m-0 jumbotron  d-flex flex-wrap bg-dark pt-5 pt-md-0"
        >
          <Navbar />
          <div className="col-md-9 col-xl-10 px-0  pr-md-4">
            <div className="row m-0  p-3 w-100 ">
              <form className="w-100 d-flex flex-wrap px-4 px-lg-5">
                <span className="col-lg-3 col-md-6 p-3 col-12">
                  <label className="text-white">انتخاب همایش</label>
                  <br />
                  <select
                    id="hamayesh"
                    name="hamayesh"
                    onChange={handleChange}
                    className="w-100 form-select mt-2"
                  >
                    <option></option>
                    {hamayeshes?.map((e) => {
                      return <option value={e._id}>{e.name}</option>;
                    })}
                  </select>
                </span>
                {selectedHamayesh && (
                  <>
                    <span className=" p-3 col-12">
                      <p className="text-white">
                        توجه : عکس هایی که جای گذاری میشوند باید با فرمت های
                        (png , jpg , jpeg) باشند و حجم فایل ها باید کمتر از دو
                        مگابایت باشد به این موارد توجه داشته باشید تا بتوانید
                        عکس ها را به راحتی آپلود کنید
                      </p>
                      <p className="text-white">
                        همینطور همه عکس ها را باهم یکجا انتخاب کنید
                      </p>
                      <label className="text-white"> عکس ها</label>
                      <br />
                      <label
                        htmlFor="addImage"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          position: "relative",
                          background: "gold",
                          width: "fit-content",
                          padding: "10px",
                          borderRadius: "5px",
                          margin: "19px 0",
                        }}
                      >
                        <FaPlus size={25} color="#000" /> آپلود عکس
                      </label>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "15px",
                        }}
                      >
                        {previews &&
                          previews.map((src) => {
                            return (
                              <span
                                style={{
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={src}
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <FaTrash
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDelete(src)}
                                />
                              </span>
                            );
                          })}
                      </div>

                      <input
                        onChange={(e) => {
                          handleChangeImg(e);
                        }}
                        style={{ display: "none" }}
                        id="addImage"
                        type={"file"}
                        accept="image/png, image/jpg, image/jpeg"
                      />
                    </span>
                    <button
                      type="button"
                      onClick={hadnleSend}
                      className="btn btn-danger mx-2"
                    >
                      تایید
                    </button>
                  </>
                )}
                <span className="col-12 p-3">
                  {success && (
                    <Alert
                      type={"success"}
                      contnet={"عکس ها با موفقیت اپلود شدند"}
                    />
                  )}
                  {errors && <Alert type={"error"} contnet={errors} />}
                </span>
              </form>
            </div>
          </div>
        </div>
      ) : (
        "loadnig..."
      )}
    </>
  );
};

export default UploadPhoto;
