import React from 'react'
import SigninFormAdmin from '../components/SigninFormAdmin'

const AdminLogin = () => {
  return (
    <div dir="rtl" id="bodyroot" className="is-boxed has-animations bg-dark">
        <div  className="body-wrap">
          {/* <Header /> */}
          <div style={{width:"100vw" , height:"100vh"}} className="jumbotron d-flex align-items-center">
            <div className="container">
              <div className="d-flex justify-content-center">
                <div className="hero-copy">
                  <SigninFormAdmin />
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
  )
}

export default AdminLogin
