import React from "react";

const About = () => {
  return (
    <div className="pt-7 bg-dark container-fluid header px-0">
      <div className="d-flex w-100 p-0 m-0  p-4 align-items-center flex-wrap bg-pattern2">
        <div className="z-10 d-flex w-100 p-0 m-0  p-4 align-items-center flex-wrap">
          <span className="col-md-6 d-flex justify-content-center my-4 my-md-0">
            <img
              src="/logo.png"
              alt="about"
              className="col-md-9 col-12 m-0 p-0"
            />
          </span>
          <span className="col-md-6 px-4">
            <h1 className="pb-3">درباره ما</h1>
            <p style={{ textAlign: "justify" }}>
              گروه شمع فعالیت خود را با عنوان سازمان شیشه ای (گروه تلگرامی) در
              بهمن ماه سال <span className="number_farsi"> 1398 </span> آغاز
              نمود. این گروه در جهت کمک به اعضای سازمان و رفع مشکلات و پاسخگویی
              مستقیم توسط اعضای هیات مدیره وقت سازمان نظام مهندسی ساختمان استان
              تهران ایجاد شد. این گروه در حال حاضر نزدیک به
              <span className="number_farsi"> 8000 </span> عضو واقعی و مهندس
              دارد.
            </p>
            <p style={{ textAlign: "justify" }}>
              در سال <span className="number_farsi"> 1400 </span> فعالیت این
              گروه با عنوان ستاد شمع که مخفف سه کلمه (شفافیت، معیشت و عدالت) به
              فعالیت خود ادامه داد.
            </p>
            <p style={{ textAlign: "justify" }}>
              در حال حاضر گروه شمع در جهت ارج نهادن به اعضای محترم سازمان و
              حمایت از مهندسان، سازندگان، طراحان و تولیدکنندگان فعالیت می نماید.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default About;
