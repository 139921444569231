import React from "react";
import Loading from "./Loading";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
  return (
    <div
      style={{ maxWidth: "1600px", margin: "auto" }}
      dir="rtl"
      className="bg-white p-0"
    >
      <Loading />
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
