import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { httpReauest } from "../api/api";
import Countdown from "react-countdown";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { CDN_BASE_URL } from "../config";

export const HamayeshJson = [
  {
    name: "جشن روز مهندس",
    date: "چهارم اسفند ماه 1402",
    location: "مرکز همایش های صدا و سیما",
    img: "/p1.png",
    id: 1,
    type: "normal",
    end: false,
  },
  {
    name: "دومین همایش ورزش تفریحی کودکان و نوجوانان",
    date: "بیست و دو دی ماه 1402",
    location: "ایران مال طبقه OG,مرکز سرگرمی لوتوس",
    img: "/2.jpg",
    id: 2,
    type: "sport",
    end: false,
  },
  {
    name: "همایش ورزش تفریحی کودکان و نوجوانان",
    date: "بیست و دو دی ماه 1402",
    location: "ایران مال طبقه OG,مرکز سرگرمی لوتوس",
    img: "/1.jpg",
    id: 3,
    type: "sport",
    end: true,
  },
];

const HamayeshDetail = () => {
  const [data, setData] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { id } = useParams();

  const clockRef = useRef();
  const handleStart = () => clockRef.current.start();

  useEffect(() => {
    getData(id);
  }, []);

  async function getData(query) {
    const data2 = await httpReauest("GET", `/user/hamayesh/${query}`, {}, {});
    setData(data2?.data?.data);
    setTimeout(() => {
      handleStart();
    }, 2000);
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
      return <h1>End</h1>;
    }

    return (
      <span className="bg-white border border-primary p-2 d-flex  rounded border-3">
        <span className="col-6 px-2 d-flex flex-column justify-content-center align-items-center">
          <h1 className="display-5 m-0 text-blue">
            {hours < 10 ? "0" + hours : hours}
          </h1>
          <h6 className="text-warning">ساعت</h6>
        </span>
        <span className="col-6 px-2 d-flex flex-column justify-content-center align-items-center">
          <h1 className="display-5 m-0 text-blue">
            {days < 10 ? "0" + days : days}
          </h1>
          <h6 className="text-warning">روز</h6>
        </span>
      </span>
    );
  };

  return (
    <div className="pt-7 bg-dark container-fluid header px-0">
      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <img
          className="w-100"
          src={CDN_BASE_URL + data?.img}
          alt={data?.name}
        />
      </Modal>
      <div className="w-100 bg-bg1 p-4 py-5 p-md-5 row m-0 ">
        <div
          style={{ zIndex: "20" }}
          className="col-md-7 d-flex align-items-center row p-0 m-0"
        >
          <div onClick={openModal} className="col-md-5">
            \
            <img
              className="w-100 rounded border border-primary border-3 pointer"
              src={CDN_BASE_URL + data?.img}
              alt={data?.name}
            />
          </div>
          <div className="col-md-7 pe-5 py-4">
            <h1 className="display-5 text-white ">{data?.name}</h1>
            <h6 style={{ color: "#e9e9e9" }} className="pt-3">
              تاریخ برگزاری : {data?.date}
            </h6>
            <h6 style={{ color: "#e9e9e9" }} className="">
              محل برگزاری : {data?.location}
            </h6>
          </div>
        </div>
        <div
          style={{ zIndex: "20" }}
          className="col-md-5 d-flex gap-3 flex-column align-items-center justify-content-center"
        >
          {!data?.end ? (
            <>
              {data?.moment && (
                <Countdown
                  autoStart={false}
                  date={data?.moment}
                  renderer={renderer}
                  ref={clockRef}
                />
              )}
              {data?.expo ? (
                data?.type == "sport" ? (
                  <a
                    href={"/expo/" + data?._id + "?title=help"}
                    className="btn btn-primary  d-flex align-items-center gap-2 py-2 px-4"
                  >
                    <FaAngleRight size={22} /> جهت حمایت مالی و معنوی
                  </a>
                ) : (
                  <a
                    href={"/expo/" + data?._id}
                    className="btn btn-primary  d-flex align-items-center gap-2 py-2 px-4"
                  >
                    <FaAngleRight size={22} /> رزرو غرفه
                  </a>
                )
              ) : null}

              <a
                href={"/sign/" + data?._id}
                className="btn btn-primary d-flex align-items-center gap-2 py-2 px-4"
              >
                <FaAngleRight size={22} /> پیش ثبت نام
              </a>
            </>
          ) : (
            <>
              <h1 className="text-primary">روادید برگزار شد </h1>{" "}
              <a
                href={"/gallery/" + data?._id}
                className="btn btn-primary  d-flex align-items-center gap-2 py-2 px-4"
              >
                <FaAngleRight size={22} /> دیدن گالری
              </a>
            </>
          )}
        </div>
      </div>

      <div className=" bg-pattern2 p-4 ">
        <div className="z-10 d-flex justify-content-center flex-wrap gap-4">
          {data?.video && (
            <>
              <h1 className="mb-2 mt-2 w-100 text-center">تیزر {data?.name}</h1>
              <video
                style={{ maxWidth: "750px" }}
                src={data.video}
                className="w-100 rounded shadow col-lg-6 m-0"
                controls
                alt={data?.name}
              />
            </>
          )}
        </div>
      </div>

      <div className="container-xxl py-5 bg-white">
        <div className="container">
          <div className="bg-light rounded p-3">
            <div
              className="bg-white rounded p-4"
              style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
            >
              <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                  <iframe
                    src={data?.iframe}
                    width="100%"
                    loading="lazy"
                    height="400"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                  <div className="mb-4">
                    <h1 className="mb-4">زمان و مکان {data?.name}</h1>
                    <h4>
                      {data?.date}
                      <br />
                      {data?.location}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HamayeshDetail;
